import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useTranslation } from 'react-i18next'
import { useStores } from '@hooks/useStores'
import { useObserver } from "mobx-react-lite"
import Link from "@components/Link"
import SEO from "@components/Seo"
import FooterSns from "@components/blocks/global/FooterSNS"
import Footer from "@components/blocks/global/Footer"
// import Header from "@components/blocks/global/Header"
import PageHeader from "@components/blocks/common/PageHeader"
import PageSectionTitle from "@components/blocks/common/PageSectionTitle"
import Product from "@components/blocks/vocaloid/Product"
import MovieList from "@components/blocks/vocaloid/MovieList"
import OfficalWorks from "@components/blocks/index/OfficialWorks"

import css from "@css/components/blocks/common/Page.module.styl"

export default function Vocaloid(props)
{
	const {t} = useTranslation()
    const { appStore } = useStores()
    const [currentLang] = useObserver(()=>[
        appStore.currentLang
    ])

	const { thumbnail, allWordpressWpOfficialsong} = props.data
	const movieData = [
		{
			title: t('vocaloid__songTitle1'),
			text: t('vocaloid__songText1'),
			url: 'https://www.youtube.com/embed/8o7cFXfOJLY'
		},{
			title: t('vocaloid__songTitle2'),
			text: t('vocaloid__songText2'),
            url: 'https://www.youtube.com/embed/0dlAbHms4rc'
		}
	]

	return (
		<React.Fragment>
			<SEO title={'VOCALOID™'} />
			<main className={css.container} id="main">
				<PageHeader l1="VOCALOID™" l2={t('vocaloid__headerTitle2')}/>
				<div className={css.content}>
					<div className={css.vocaloid}>
						<section className={css.section}>
							<Product/>
						</section>
						<section className={css.section}>
							<PageSectionTitle l1={t('vocaloid__sectionTitle1')} l2={t('vocaloid__sectionTitle2')}/>
							<MovieList data={allWordpressWpOfficialsong.edges} currentLang={currentLang}/>
							<Link to='/songs/' className={css.button}>{t('vocaloid__sectionButton1')}</Link>
						</section>
						<section className={css.section}>
							<PageSectionTitle l1={t('index__supportTitleOfficialWorks')} l2={t('index__officialWorksTitle2')} />
							<OfficalWorks contentMode />
						</section>
					</div>
				</div>
			</main>
			<FooterSns/>
			<Footer/>
		</React.Fragment>
	)
}

export const pageQuery = graphql`
    query {
        thumbnail : file(relativePath: { eq: "sample__img__thumbnail02@2x.png" }) {
            childImageSharp {
                fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        allWordpressWpOfficialsong (sort: { fields: date, order: DESC }) {
            edges {
                node {
                    id
                    title
                    content
                    date(formatString: "MMMM DD, YYYY")
                    acf {
                        officialsong_id
                        officialsong_title_en
                        officialsong_text_en
                    }
                }
            }
        }
    }
`