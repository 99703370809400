import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FluidImage } from "@components/Image"
import { useTranslation } from 'react-i18next'
import { useURL } from '@hooks/useURL'

import Link from "@components/Link"

import css from "@css/components/blocks/vocaloid/Product.module.styl"

export default function VocaloidProduct()
{
    const {t} = useTranslation()
    const { miraiImg } = useStaticQuery(graphql`
        query VocaloidProductQuery {
            miraiImg : file(relativePath: { eq: "Vocaloid__img__product@3x.png" }) {
                childImageSharp {
                    fluid(maxWidth: 640) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    const tableData = [
        {
            label: t('vocaloid__productTable1-1'),
            value: t('vocaloid__productTable1-2'),
        },{
            label: t('vocaloid__productTable2-1'),
            value: t('vocaloid__productTable2-2'),
        },{
            label: t('vocaloid__productTable3-1'),
            value: t('vocaloid__productTable3-2'),
        },{
            label: t('vocaloid__productTable4-1'),
            value: t('vocaloid__productTable4-2'),
        }
    ]

    const urls = useURL()

    return(
        <div className={css.container}>
            <div className={css.content}>
                <figure className={css.thumbnail}>
                    <FluidImage data={miraiImg} />
                </figure>
                <div className={css.detail}>
                    <h2 className={css.title} dangerouslySetInnerHTML={{__html: t('vocaloid__productTitle1')}}/>
                    <p className={css.text} dangerouslySetInnerHTML={{__html: t('vocaloid__productText1')}}/>
                    <ul className={css.table}>
                        {
                            tableData.map(( { label, value },i )=>{
                                return(
                                    <li key={i} className={css.item}>
                                        <span className={css.label}>{label}</span>
                                        <span className={css.value}>{value}</span>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <p className={css.text_small} dangerouslySetInnerHTML={{__html: t('vocaloid__productSmallText1')}}/>
                    <Link className={css.button} to={urls.store} target="_blank">{t('vocaloid__productButton1')}</Link>
                </div>
            </div>
        </div>
    )
}
